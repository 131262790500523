var fn={};
var player;
$.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
});

$(window).load(function() {
	// Animate loader off screen
	if($('.logo-loader').length > 0){
		$(".logo-loader").fadeOut("slow");
	}
});
$(function(){
	fn = {
		launch:function(){
			fn.core();
			fn.applyMatchHeight();
			fn.windowDependentFn();
			fn.applyOwlCarousel();
			fn.applyBxSlider();
			fn.filterDropdown();
			fn.quickShopBtn();
			fn.commentBox();
			fn.showCase();
			fn.addToCartForm();
			fn.wishlistCartForm();
			fn.countrySelection();
			fn.validateForms();
			fn.checkOutForm();
			fn.placeOrder();
			fn.afterRegistration();
			fn.disqusComments();
			fn.applyDatePicker();
			fn.usedToys();
			fn.addedToCart();
			fn.slick();
			fn.tab_to_select();
			fn.color_select();
			fn.starrating();
			fn.progressiveImage();
		},
		core: function(){// for navbar and bottom to top button
			if($(".navbar").length > 0){
				$(".navbar").sticky({topSpacing:0});
			}
			$(document).on('click','.yamm .dropdown-menu', function(e) {
			  e.stopPropagation()
			});
			$(".up a").click(function(){
				$("html,body").animate({
					scrollTop:0
				},2000);
				return false;
			});

			$(".floating-menu #menu-toggle").on("click",function(){
				$("html,body").animate({
					scrollTop:$(".navbar-default.yamm").offset().top

				},400);
				return false;
			});


			$(".quick-shop-btn").hover(function(){

				$(this).parent(".image").find(".quick-shop-link").addClass("hover");	
			},function(){
				$(this).parent(".image").find(".quick-shop-link").removeClass("hover");	
			});

			$(".brand-lists a").on("click",function(){
				$("html,body").animate({
					scrollTop:$( $(this).attr('href') ).offset().top - 70
				},1000);
				return false;
			});


			// phone-form side

			$(".phone .toggle-btn").on("click",function(){
				$(this).toggleClass("open");
				$(".content .close").fadeIn();
				$(".phone-form").slideToggle(500);

			});

			$(".phone .content .close").click(function(){
				$(this).fadeOut(300);
				$(".phone .phone-form").slideUp(500);
				$(".phone .toggle-btn").removeClass("open");
			});

			$(".dropdown .dropdown-menu .list__item > a").on("mouseover",function(){
				$(".dropdown .dropdown-menu .list__item").removeClass("open").find(".list__item--sub").fadeOut(200);
				$(this).parent(".list__item").addClass("open").find(".list__item--sub").fadeIn(200);
			});

			$(document).on("mouseleave",".dropdown .dropdown-menu .list__item .list__item--sub",function(){
				$(this).fadeOut();
				$(this).parent(".list__item").removeClass("open");
			});

			$(document).on("click",'.dropdown .dropdown-menu .list__item .list__item--sub .dropdown-close',function(){
				$(this).parent('.list__item--sub').fadeOut();
				$(this).parent(".list__item--sub").parent('.list__item').removeClass("open");
			});


			var userHeight=($(".profile-description .user-detail").height()+40)+"px";
			
			var subscribe=$(".subscribtion-submit");
			if($(window).width() > 767){
				$(window).on("load",function(){
					$(subscribe).css({
						"top":userHeight
					})
				})
			}


			// arrival tab link width
			var tab_container=$(".arrival-tab .tab-block .nav-tabs");
			var total_link=$(tab_container).find("li").length;
			var per_tab_width=100/total_link;
			$(tab_container).find("li").width(per_tab_width+"%");

			var colors=["yellow","blue","red","green","purple","maroon","light-blue"];
			var total_colors=colors.length;
			$j=0;
			$(tab_container).find("li").each(function(i){
				if($j > (total_colors-1)){
					colors[$j]=colors[0];
				}
				$(this).addClass("color-"+colors[$j]);
				console.log($j);
				$j++;


			});

			$(".review-link a").on("click",function(){
				$(".review-form").slideToggle();
				return false;
			});			

			
		},
		applyMatchHeight:function(){
			$(".footer__top .footer__top--block").matchHeight();
			$(".product-list__content--cell .product-name").matchHeight();
			$(".product-list__content--cell").matchHeight();
			$(".wishlist-main-content .wishlist-cell").matchHeight();
			$(".recently-viewed__row .product-list__content .caption").matchHeight({
				byRow:false
			});
			$(".section-inner__review-order ul").matchHeight();
			$(".blog-block .text h1.title").matchHeight({
				byRow:false
				
			});

			$(".used-toy-section").matchHeight();

			function tab_link_height(){
				$(".arrival-tab .tab-block .nav-tabs li a").matchHeight();
			}

			$(window).on("load",function(){
				tab_link_height();
			});

			$(window).resize(function(){
				tab_link_height();
			})
			
		},
		applyDatePicker:function(){
			/**** CALENDAR ****/
		    $('.dateSelect input').datepicker({
		        format: "yyyy-mm-dd",
		        weekStart: 0,
		        todayBtn: "linked",
		        autoclose:true
		    });
		    /** Trigger the calendar when clicked on some button or element **/
		    $('.dateSelect .trigger-cal').click(function(){
		        $(this).siblings('input').focus();
		    });
		},
		windowDependentFn:function(){
			var win = $(window);
			$(win).resize(function(){
				if($(win).width() < 768 ){
					$(".menu-big-container .row").addClass('owl-carousel');
				}else{
					$(".menu-big-container .row").removeClass('owl-carousel');
				}
			});

			if($(win).width() < 768 ){
				$(".menu-big-container .row").addClass('owl-carousel');
			}else{
				$(".menu-big-container .row").removeClass('owl-carousel');
			}
			if($(win).width() < 541){
				$(".section__wrapper .category .row .col-xs-6 h2").click(function(){
					$(this).next(".list").slideToggle("fast");
				})
			}
		},
		applyOwlCarousel: function(){


			if($(".owl-carousel").length > 0){
				  $(".owl-carousel").owlCarousel({
				  	autoPlay:true,
				  	slideSpeed:2000,
				  	nav : true,
			    	navText : ["<i class='zmdi zmdi-long-arrow-left'></i>","<i class='zmdi zmdi-long-arrow-right'></i>"],
			    	responsive : {
					    // breakpoint from 0 up
					    0 : {
					       items:1
					    },
					    // breakpoint from 480 up
					    480 :{
					    	items:2
					    },
					    767: {
					        items:2   
					    }
				  }
				});

			  }
			  if($(".related-products .row.related-products__row").length>0){
			  	$(".related-products .row.related-products__row ").owlCarousel({
					items:1,
					center:true,
					loop:true,
				    nav:true,
				    navText:["<i class='zmdi zmdi-chevron-left'></i>","<i class='zmdi zmdi-chevron-right'></i>"],
				    // itemsDesktop : [1199,4],
				    // itemsDesktopSmall : [980,3],
				    // itemsTablet: [768,3],
				    // itemsTabletSmall: [660,2],
				    // itemsMobile : [480,2],
				    loop:true,
				    autoplayTimeout:5000,
				    autoplay:true,
				    autoplayHoverPause:true,
				
				     responsive : {
					    // breakpoint from 0 up
					    0 : {
					       items:2
					    },
					    // breakpoint from 480 up
					    660 : {
					        items:2   
					    },

					    // breakpoint from 768 up
					    768 : {
					        items:3
					    },
					    980 :{
					    	items:3
					    },
					    1200 :{
					    	items:5
					    },
					    1300 :{
					    	items:5
					    }

					}
			
				});
			  }
			  if($(".recently-viewed .row.recently-viewed__row").length>0){
			  	$(".recently-viewed .row.recently-viewed__row .recently-view__block").owlCarousel({
					// items:1,
					loop:true,
					center:true,
				    nav:true,
				    navText:["<i class='zmdi zmdi-chevron-left'></i>","<i class='zmdi zmdi-chevron-right'></i>"],
				 	
				    responsive : {
					    // breakpoint from 0 up
					    0 : {
					       items:2
					    },
					    // breakpoint from 480 up
					    480 : {
					        items:2   
					    },
					    // breakpoint from 768 up
					    768 : {
					        items:3
					    },
					    980 :{
					    	items:4
					    },
					    1200 :{
					    	items:5
					    },
					    1300 :{
					    	items:5
					    }

					}

				});
			  }

			  if($(".home-feature-slider").length > 0){

			  	$(".home-feature-slider .product-list__content").each(function(){
			  		var _this = $(this);
				  	_this.owlCarousel({
				  		items:1,
					    nav:true,
					    margin:20,
					    loop:true,
					    center:true,
					    autoplay:true,
					    autoplayTimeout:3000,
					    autoplayHoverPause:true,
					    navText:["<i class='zmdi zmdi-chevron-left'></i>","<i class='zmdi zmdi-chevron-right'></i>"],
					    // itemsDesktop : [1199,4],
					    // itemsDesktopSmall : [980,3],
					    // itemsTablet: [768,3],
					    // itemsTabletSmall: [660,2],
					    // itemsMobile : [480,2],
					

						responsive : {
						    // breakpoint from 0 up
						    0 : {
						        items:1,
						        margin:5
						    },
						    // breakpoint from 480 up
						    480 : {
						        items:2,
						        margin:5,
						    },
						    // breakpoint from 768 up
						    768 : {
						        items:3
						    },
						    980 :{
						    	items:3
						    },
						    1200 : {
						    	items:5
						    },
						    1300 :{
						    	items:5
						    }
						},
					
				  	});
				  	//fn.hideShowOwlButtons(_this);
			  	});
			  }


			  function owlControlNav($wrapper, $item, $item_count) {
                var viewport = $(window).width();
			    var itemCount = $($wrapper).find($item).length;
	                if ($($wrapper).length > 0) {
	                    
	                    console.log(itemCount);
	                    if (
	                        (viewport >= 900 && itemCount <= $item_count) //desktop
	                        || ((viewport >= 600 && viewport < 900) && itemCount <= $item_count - 1) //desktopsmall
	                        || ((viewport >= 479 && viewport < 600) && itemCount <= 2) //tablet
	                        || (viewport < 479 && itemCount <= 1) //mobile
	                    ) {
	                    	
	                        $($wrapper + ' .owl-controls .owl-nav').hide();
	                    } else {

	                        $($wrapper + ' .owl-controls .owl-nav').show();
	                    }
	                }
	            }

	        	// recently viewed

	            owlControlNav(".recently-viewed .row.recently-viewed__row ", ".product-list__content", 3);

	            // related products

	            owlControlNav(".related-products .row.related-products__row ", ".product-list__content", 3);

	            // featured products

	            owlControlNav(".home-feature-slider .product-list__content", ".product-list__content--cell", 4);
		},
		hideShowOwlButtons: function(_parent){
			var viewport = jQuery(window).width();
			var itemCount = _parent.find('.product-list__content--cell').length;

			if(
				(viewport <=399 && itemCount <= 2) ||
			    (viewport >= 480 && viewport <=767 && itemCount <=2 ) || 
			    (viewport >= 768 && viewport <=979 && itemCount <=3 ) || 
			    (viewport >= 980 && viewport <=1199 && itemCount <=3 ) ||
			    (viewport >= 1200 && viewport <=1299 && itemCount <=5 ) ||
			    (viewport >= 1300 && itemCount <=5 )
			)
			{
			     _parent.find('.owl-prev, .owl-next').hide();
			} 
			else
			{
			     _parent.find('.owl-prev, .owl-next').hide();
			}
		},
		applyBxSlider: function(){
			if((".full-display-slider").length >0){

				var slider = $(".full-display-slider").bxSlider({
					pagerCustom: '#thumb-pager',
					'controls':false,
					captions: true,
					autoDelay:2000,
					infiniteLoop:false,
					auto:false
				});
				$('.lazy-load').lazy({
				    visibleOnly: true,
				    effectTime:1000,
				    effect:'fadeIn',
				    combined:true,
				    enableThrottle: true,
            		throttle: 250,
				    afterLoad:function(element){
				        element.removeAttr('style');
				        element.removeClass('lazy-load');
				        element.siblings('.slider-preloader').remove();
				        slider.redrawSlider();
				    }

				});
				lightbox.option({
					/*fitImagesInViewport:false,*/
					positionFromTop:25
				});

				if($(window).width() > 991){

					$("#thumb-pager").bxSlider({
						mode:'vertical',
						 minSlides:5,
	 					 maxSlides: 5,
	 					 slideWidth: 92,
	  					 slideMargin: 20,
	  					 pager:false,
	  					 infiniteLoop:false,
	  					 hideControlOnEnd:true
	  					 
					});
				}


				if($(window).width() < 992){

					$("#thumb-pager").bxSlider({
						mode:'horizontal',
						 minSlides:3,
	 					 maxSlides: 3,
	 					 slideWidth: 92,
	  					 slideMargin: 20,
	  					 pager:false,
	  					 
					});
				}

			}
		},
		controlYoutubeVideo:function(){
			// Inject YouTube API script
			var tag = document.createElement('script');
			tag.src = "//www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		},
		filterDropdown: function(){
			$(".filter-list__block .dropdown-toggle").on("click",function(event){
				 event.stopPropagation();
				// $(".section-inner__filter").find(".filter-list__block").removeClass("open");
				$(this).parent(".filter-list__block").toggleClass("open").siblings().removeClass("open");
				$(this).siblings('.filter-list__block--dropdown').click(function(e){
					e.stopPropagation();
				});
			});

			$(".filter-list__block .filter-list__block--dropdown label").on("click",function(event){
				event.stopPropagation();
			});

			$("html").on("click",function(event){

			 	$(".section-inner__filter").find(".filter-list__block").removeClass("open");
			 	$(".filter-list__block .filter-list__block--dropdown label").on("click",function(e){
					e.stopPropagation();
				});
			});
		},
		quickShopBtn:function(){
			// wishlist hightlight
			if($(window).width() < 768){
				var wishlist="Wishlist";

			}else{
				var wishlist="Add to Wish List";
			}
			$(document).on("click",".quick-shop-btn .wishlist",function(){
				var _this = this;
				var id = $(_this).siblings('.product_id').val();
				console.log(id);
				var defIcon = "zmdi-favorite-outline";var loadIcon = "zmdi-hc-spin zmdi-spinner";
				$(".zmdi",_this).removeClass(defIcon);
				$(".zmdi",_this).addClass(loadIcon);
				$(_this).find('span').html('loading...');
				var _form = $(this).parents('form');
				$.ajax({
					url:_form.attr('action'),
					data:_form.serialize(),
					type:'POST',
					/*async:false,*/
					dataType:'json',
					success:function(data){
						if(data.status=='success'){
							$('#wishlist-count').html(data.count);
							if(data.process=='removed'){
								$(".wishPro_"+id+" .zmdi").removeClass("zmdi-favorite");
								$(".wishPro_"+id).find('span').html(wishlist);
							}else{
								console.log('addded');
								$(".wishPro_"+id+" .zmdi").addClass("zmdi-favorite");
								$(".wishPro_"+id).find('span').html('Remove');
							}
							$(".wishPro_"+id).toggleClass("selected");
							$(".zmdi",_this).addClass(defIcon);
							$(".zmdi",_this).removeClass(loadIcon);
							console.log(data);
						}else{
							alert('Something went wrong. Sorry for this incovenience!');
						}
							
					}
				});
				return false;
			});

			// shopping cart highlight
			$(document).on("click",".quick-shop-btn .shop-btn",function(){
				$(this).toggleClass("selected");
				$(".zmdi",this).toggleClass("zmdi-search").toggleClass("zmdi-zoom-in");
			});
		},
		starrating:function(){
			$(".review-form .star-rate").rating();
		},
		loadReviews:function(){
        	var offset = 0,totalCount=$('#load-more-reviews').data('total');

        	$('#load-more-reviews').on('click',function(){
        		var _this = $(this);
        		var load = $('#fountain-bars').attr('src');
        		var limit = _this.data('limit'), loadUrl = _this.data('url'), pid = _this.data('pid')
        		var ct = _this.data('content');
        		offset = offset + limit;
        		_this.attr('disabled','disabled');
        		_this.html('<img src="'+load+'"/>');

        		$.ajax({
        			url:loadUrl,
        			data:{limit:limit,offset:offset,pid:pid},
        			type:'post',
        			dataType:'json',
        			success:function(data){
        				if(data.status=='success'){
        					var html = data.html;
        					var el = jQuery(html);
							$('#reviews-list ul').append(el);
							$('.cur-rev-count').html(offset+data.count);
        					if(limit+offset >= totalCount){
        						_this.hide();
        					}
        					//console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
        				}
        			},
        			complete:function(){
        				_this.removeAttr('disabled');
        				_this.html(ct);
        			}
        		})
        	});
        },
        loadTestimonials:function(){
        	console.log('called');
        	var offset = $('#load-more-testimonials').data('offset'),totalCount=$('#load-more-testimonials').data('total');
        	console.log("offset:"+offset + " & total:"+totalCount)
        	$('#load-more-testimonials').on('click',function(){
        		var _this = $(this);
        		var load = $('#fountain-bars').attr('src');
        		var limit = _this.data('limit'), loadUrl = _this.data('url');
        		var ct = _this.data('content');
        		var ex = _this.data('exclude');
        		
        		_this.attr('disabled','disabled');
        		_this.html('<img src="'+load+'"/>');

        		$.ajax({
        			url:loadUrl,
        			data:{limit:limit,offset:offset,exclude:ex},
        			type:'post',
        			dataType:'json',
        			success:function(data){
        				if(data.status=='success'){
        					var html = data.html;
        					var el = jQuery(html);
							$('.testimonial-wrapper ul').append(el);
							offset = offset + limit;
        					if(offset+limit > totalCount){
        						_this.hide();
        					}
        					console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
        				}
        			},
        			complete:function(){
        				_this.removeAttr('disabled');
        				_this.html(ct);
        			}
        		})
        	});
        },
		disqusComments:function(){
            /* * * CONFIGURATION VARIABLES * * */
            var disqus_shortname = 'babyshopnepal';
            var disqus_developer = 1;
            
            /* * * DON'T EDIT BELOW THIS LINE * * */
            (function () {
                var s = document.createElement('script'); s.async = true;
                s.id = 'dsq-count-scr';
                s.type = 'text/javascript';
                s.src = '//' + disqus_shortname + '.disqus.com/count.js';
                (document.getElementsByTagName('BODY')[0]).appendChild(s);
            }());
        },
		commentBox:function(){
			// comment
			$(".comment a").on("click",function(){
				$("html,body").animate({
					scrollTop:$(".section-inner__comment-box").offset().top -100
				},2000);
				return false;
			});

		},
		showCase:function(){
			$('.show-showcase').click(function(e){
				e.preventDefault();
				$('html,body').animate({
					scrollTop: $('#show-product-filters').offset().top - 80
				},1000);
			});
		},
		addToCartForm: function(){
		    /** in the add to cart form **/
			if($('#product-single').length>0){
				var _elm = $('#product-single');
				var qty = _elm.data('quantity'), price = _elm.data('price'),
					saleprice = _elm.data('saleprice'), name = _elm.data('name'),
					us_price = _elm.data('usprice');
				fn.applyQuantity(qty,name);
				fn.applyPrice(price, saleprice);
				fn.applyUSPrice(us_price);
			}else{
				var _selected = $('#product-variant option:selected');
				var qty = _selected.data('quantity'), price = _selected.data('price'),
					saleprice = _selected.data('saleprice'), name = _selected.data('name'),
					us_price = _selected.data('usprice');
				fn.applyQuantity(qty,name);
				fn.applyPrice(price, saleprice);
				fn.applyUSPrice(us_price);

				$('#product-variant').change(function(){
					var _selected = $(this).find('option:selected');
					var quantity = _selected.data('quantity');
					var price = _selected.data('price');
					var orgPrice = _selected.data('original-price');
					var name = _selected.data('name');
					fn.applyQuantity(quantity,name);
					fn.applyPrice(price, saleprice);
					fn.applyUSPrice(us_price);
				});
			}
		},
		applyQuantity:function(qty,name,elms){
			var q = '#product-quantity', vname = '#vName', mqty = '#maxQty';
			var cName,sName;
			if(elms != undefined){
				if(elms.q!=undefined){ q= elms.q; }
				if(elms.vname!=undefined){ vname= elms.vname; }
				if(elms.mqty!=undefined){ mqty= elms.mqty; }
				if(elms.cName!=undefined){ cName= elms.cName; }
				if(elms.sName!=undefined){ sName= elms.sName; }
				if(elms.cNameVal!=undefined){ cNameVal= elms.cNameVal; }
				if(elms.sNameVal!=undefined){ sNameVal= elms.sNameVal; }
			}

			if(qty == 0 || qty == undefined){
				$(q).html("<option value='0'>0</option>");
				$(q).attr('disabled','disabled');
				$(q).css('opacity','0.8');
				$(vname).val('');
				$(mqty).val(0);
				if(elms && elms.cName!=undefined){ $(cName).val(''); }
				if(elms && elms.sName!=undefined){ $(sName).val(''); }
			}else{
				$(mqty).val(qty);
				var opts = "";
				if(qty>7){ qty = 7; }
				for (var i = 1; i <= qty; i++) {
					opts +="<option value='"+i+"'>"+i+"</option>";
				};
				$(q).html(opts);
				$(q).removeAttr('disabled');
				$(q).css('opacity','1');
				$(vname).val(name);
				if(elms && elms.cName!=undefined){ $(cName).val(cNameVal); }
				if(elms && elms.sName!=undefined){ $(sName).val(sNameVal); }
			}
		},
		applyPrice:function(price,orgPrice,elms){
			var pElm = '#prdPrice',oElm = '#prdOrgPrice';
			if(elms != undefined && elms.pElm!=undefined){ pElm= elms.pElm; }
			if(elms != undefined && elms.oElm!=undefined){ oElm= elms.oElm; }
			var showPrice=$(pElm).data('original');
			var appPrice = showPrice,appOrgPrice=showPrice ;
			if(price>0 && price !=undefined){
				appPrice = price;
			}
			if(orgPrice>0 && orgPrice !=undefined){
				appOrgPrice = orgPrice;
			}
			$(oElm).val(appOrgPrice);
			$(pElm).val(appPrice);
		},
		applyUSPrice:function(price,elms){
			var uElm = '#prdUSPrice';
			if(elms != undefined && elms.uElm!=undefined){ uElm= elms.uElm; }
			var showPrice=$(uElm).data('original');
			var appPrice = showPrice;
			if(price>0 && price !=undefined){
				appPrice = price;
			}
			$(uElm).val(appPrice);
		},
		wishlistCartForm: function(){
			$('.wishlist-cart-form').each(function(){
				var id = $(this).attr('id');
				fn.variantSelection('#'+id);
				/*if($('#'+id+' .product-single').length>0){
					var _elm = $('#'+id+' .product-single');
					var qty = _elm.data('quantity'), price = _elm.data('price'),us_price = _elm.data('usprice'),
						saleprice = _elm.data('saleprice'), name = _elm.data('name');
					var qElms = {'q':'#'+id+' .product-quantity','vname':'#'+id+' .vName','mqty':'#'+id+' .maxQty'};
					var pElms = {'pElm':'#'+id+' .prdPrice','oElm':'#'+id+' .prdOrgPrice'};
					var uElms = {'uElm':'#'+id+' .prdUSPrice'};
					fn.applyQuantity(qty,name,qElms);
					fn.applyPrice(price, saleprice,pElms);
					fn.applyUSPrice(us_price,uElms);
				}else{
					var _selected = $('#'+id+' option:selected');
					var qty = _selected.data('quantity'), price = _selected.data('price'),us_price = _selected.data('usprice'),
						 saleprice = _selected.data('saleprice'), name = _selected.data('name');
					var qElms = {'q':'#'+id+' .product-quantity','vname':'#'+id+' .vName','mqty':'#'+id+' .maxQty'};
					var pElms = {'pElm':'#'+id+' .prdPrice','oElm':'#'+id+' .prdOrgPrice'};
					var uElms = {'uElm':'#'+id+' .prdUSPrice'};
					fn.applyQuantity(qty,name,qElms);
					fn.applyPrice(price, saleprice,pElms);
					fn.applyUSPrice(us_price,uElms);
					$('#'+id+' .product-variant').on('change',function(){
						var _selected = $(this).find('option:selected');
						var quantity = _selected.data('quantity');
						var price = _selected.data('price'), us_price = _selected.data('usprice');
						var orgPrice = _selected.data('original-price');
						var name = _selected.data('name');
						fn.applyQuantity(quantity,name,qElms);
						fn.applyPrice(price, orgPrice,pElms);
						fn.applyUSPrice(us_price,uElms);
					});
				}*/
			});

		},
		variantSelection:function(formId){
			if($('.select-color').length>0){
				fn.applySizes(formId);
			}
			fn.processVariants(formId);
			$(formId).find('.select-color').on('change',function(){
				fn.applySizes(formId);
				fn.processVariants(formId);
			});
			$(formId).find('.select-variant').on('change',function(){
				fn.processVariants(formId);
			});
		},
		applySizes:function(formId){
			var _color = $(formId).find('.select-color:checked');
			var options = _color.data('sizes');
			var _sizes = $(formId).find('.select-variant');
			if(options){
				_sizes.html("");
				$.each(options,function(index,elm){
					var content = "<option value='"+elm.id+"'>"+elm.value+"</option>";
					_sizes.append(content);
				});
			}
		},
		processVariants:function(formId){
			var _size, _color,sizeId, colorId, cname="",sname="";
			var qElms = {'q':formId+' .product-quantity','vname':formId+' .vName','mqty':formId+' .maxQty'};
			var pElms = {'pElm':formId+' .prdPrice','oElm':formId+' .prdOrgPrice'};
			var uElms = {'uElm':formId+' .prdUSPrice'};

			if($(formId).find('.product-single').length>0){
				var _elm = $(formId).find('.product-single');
				var qty = _elm.data('quantity'), price = _elm.data('price'),
					orgprice = _elm.data('original-price'), name = _elm.data('name'),
					us_price = _elm.data('usprice');

				fn.applyQuantity(qty,name,qElms);
				fn.applyPrice(price, orgprice, pElms);
				fn.applyUSPrice(us_price,uElms);
			}else{
				qElms.sName = formId+' .sName';qElms.cName=formId+' .cName';//define size and color elms
				var variants = $(formId).find('.variants-values').data('values');
				if($(formId).find('.color-selection-block').length>0){
					_color =  $(formId).find('.select-color:checked');
					colorId = _color.val();cname = _color.siblings('label').html();
				}
				if($(formId).find('.select-variant').length>0){
					_size = $(formId).find('.select-variant');
					sizeId = _size.val();sname = _size.find('option:selected').html();
				}
				var matchingResults = variants.filter(function(x){ return x.color_id == colorId && x.size_id==sizeId; });
				if(matchingResults instanceof Array && matchingResults[0]!=null){
					var variant = matchingResults[0];
					var varId = variant.id;
					$(formId).find('.variant-id').val(varId);

					var name = (cname!="" && sname!="")?cname.trim() +"\n"+sname.trim():(cname?cname.trim():sname.trim()); 
					var quantity = variant.quantity;
					qElms.cNameVal = cname?cname.trim():''; qElms.sNameVal = sname?sname.trim():'';
					fn.applyQuantity(quantity,name,qElms);

					var orgPrice= variant.price; var price = variant.newprice; var us_price = variant.newprice_us;
					fn.applyPrice(price, orgPrice,pElms);
					fn.applyUSPrice(us_price,uElms);
					var currency = $(formId).find('.currency_name').val(); var showPrice = price;
					if(currency == "USD"){showPrice = us_price;}
					$('span[itemprop=price]').html(fn.formatMoney(showPrice));
					
				}
			}
		},
		formatMoney: function(price, c, d, t){
			var n = price, 
		    c = isNaN(c = Math.abs(c)) ? 2 : c, 
		    d = d == undefined ? "." : d, 
		    t = t == undefined ? "," : t, 
		    s = n < 0 ? "-" : "", 
		    i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
		    j = (j = i.length) > 3 ? j % 3 : 0;
		   return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		},
		addedToCart:function(){
			/** after product is added, for the elms in the modal **/
			$(document).on('click',".choose-up",function(){
				var maxqty = $("input#chooseQty").attr("data-max");
				if(maxqty >7){maxqty=7;}
				var selQty = parseInt($("input#chooseQty").val());
		        $("input#chooseQty").val(selQty+1);
		        var selQty = parseInt($("input#chooseQty").val());
		        if(selQty>1){
		        	$(".choose-down").removeAttr('disabled');
		        }
		        if(selQty >= maxqty){
		        	$(this).attr('disabled','disabled');
		        }
		        fn.chooseQtyChanged();
		    });

		    $(document).on('click',".choose-down",function(){
		    	var maxqty = $("input#chooseQty").attr("data-max");
		    	if(maxqty >7){maxqty=7;}
				var selQty = parseInt($("input#chooseQty").val());
		        $("input#chooseQty").val(selQty-1);
		        var selQty = parseInt($("input#chooseQty").val());
		        if(selQty==1){
		        	$(this).attr('disabled','disabled');
		        }
		        if(selQty < maxqty){
		        	$(".choose-up").removeAttr('disabled');
		        }
		        fn.chooseQtyChanged();
		    });
		    $(document).on('blur','input#chooseQty',function(){
		    	var maxqty = $("input#chooseQty").attr("data-max");
		    	if(maxqty >7){maxqty=7;}
				var value = $(this).val();
				if(isNaN(value) || value<1 || value > maxqty){
					$(this).focus();
					$(this).val(1);
					alert('Please enter integer greater than zero and less than '+maxqty+'.');
				}
				var value = $(this).val();
				if(value<=1){
		        	$(".choose-down").attr('disabled','disabled');
		        }else{
		        	$(".choose-down").removeAttr('disabled');
		        }
		        if(value>=maxqty){
		        	$(".choose-up").attr('disabled','disabled');
		        }else{
		        	$(".choose-up").removeAttr('disabled');
		        }
		        fn.chooseQtyChanged();
			});
		},
		chooseQtyChanged:function(){
			_form = $('.change-qty-form');
			_inp = $("input#chooseQty");
        	_form.css('opacity','0.3');
        	_inp.attr('disabled','disabled');
		    $.ajax({
		        url: _form.attr('action'),
		        data: {rowid:_form.find('input[name=rowid]').val(), qty:_inp.val()},
		        type: 'POST',
		        success: function(response) {
		            if (response.status == 'success') {
		                $('.shopping-cart-count').html(response.cart_count);
		                $('.shopping-cart-total').html(response.cart_total);
		            }
		        },
		        complete:function(){
		        	_form.css('opacity','1');
        			_inp.removeAttr('disabled');
		        }
		    });
		},
		countrySelection: function(){
			fn.countrySelected('#billing_country','#billing_state');
			fn.countrySelected('#shipping_country','#shipping_state');
			$('#billing_country').change(function(){
				fn.countrySelected('#billing_country','#billing_state');
			});
			$('#shipping_country').change(function(){
				fn.countrySelected('#shipping_country','#shipping_state');
			});
		},
		countrySelected: function(elm,stateElm){
			var fb = $("#loaders").data('fadingblock');
			var cid = $(elm).val();
			var url = $(elm).data('url');
			var sel = $(stateElm).data('val');
			if(sel == undefined || !sel){
				sel=null;
			}

			if(cid){
				$(stateElm).parent().append("<img src='"+fb+"' class='email-check-loader'/>");
				$.ajax({
					url:url,
					data:{cid:cid,sid:sel},
					type:'post',
					success:function(data){
						$(stateElm).html($(data).html());
						$(stateElm).siblings(".email-check-loader").remove();
					}
				});
			}
		},
		checkOutForm: function(){
			$('#ck').on('change',function(){
		        if($(this).is(':checked')){
		            $('.shipping-form').hide(500);
		        }else{
		            $('.shipping-form').show(500);
		        }
		    });
		    $('#as_guest').on('change',function(){
		    	if($(this).is(':checked')){
		    		console.log($('#checkout-form').offset().top);
		    		$('.checkout-data').slideDown(500);
		    		$('#checkout-login-form').slideUp(100,function(){
			    		$('html,body').animate({
			    			scrollTop:$('#checkout-form').offset().top-150
			    		},500);
		    		});
		    	}else{
		    		$('.checkout-data').slideUp(500);
		    		console.log($('#checkout-login-form').offset().top);
		    		$('#checkout-login-form').slideDown(100,function(){
			    		$('html,body').animate({
			    			scrollTop:$('#checkout-login-form').offset().top-150
			    		},500);
		    		});
		    	}
		    });

		    $('#redeem-coupon').click(function(){
		    	var _this = $(this);
		    	var vc = $('#vc').val();
		    	if(vc==""){
		    		alert('Please enter the coupon code');
		    		$('#vc').focus();
		    		return false;
		    	}
		    	_this.html('Requesting...');
		    	_this.attr('disabled','disabled');
		    	var total = $('#cart_total').data('total');
		    	var url = $('#checkout-form').data('redeemurl');
		    	var sym =$('#cart_total').data('symbol');
		    	$.ajax({
		    		data:{coupon:vc,total:total},
		    		url:url,
		    		type:'post',
		    		dataType:'json',
		    		success:function(data){
		    			if(data.status=='success'){
		    				$('#voucher_id').val(data.voucher_id);
		    				$('#voucher_code').val(data.voucher_code);
		    				$('#voucher_discount').val(data.voucher_discount);
		    				$('#voucher_amount').val(data.voucher_amount);
		    				$('#grand_total').val(data.grand_total);

		    				$('#show_discount_total').html("- " + sym + data.voucher_amount);
		    				$('#show_grand_total').html(sym + data.grand_total);
		    				$('#show_coupon_code').html(vc);

		    				$('.coupon-fields').show();
		    				$('#vc').val('');
		    			}else if(data.status=='error'){
		    				$('#vc').val('');
		    				alert(data.message);
		    			}
		    		},
		    		complete:function(){
		    			_this.html('Redeem');
		    			_this.removeAttr('disabled');
		    		}
		    	})
		    });
		},
		placeOrder:function(){
			$('.place-order-form').find('button[type=submit]').removeAttr('disabled');
			$('.place-order-form').submit(function(){
				var btn = $(this).find('button[type=submit]');
				btn.attr('disabled','disabled');
				btn.html('<span>Requesting...</span>');
				var url = $('#place_saved_order').data('url');
				var ret = true;
				$.ajax({
					url:url,
					data:{proceed:1},
					type:'POST',
					async:false,
					dataType:'JSON',
					success:function(data){
						if(data.status="success"){

						}else{
							ret=false;
							var msg = data.message;
							btn.removeAttr('disabled','disabled');
							btn.html('<span>Place Order</span>');
							alert(msg);
						}
					}
				});
				console.log(ret);
				return ret;
			});
		},
		usedToys: function(){
			$('#post-speak').on('click',function(){
				$('.post-form').slideDown(600);
				$('#post-speak').slideUp(400);
			});
			$('.btn-cancel').on('click',function(){
				$('.post-form').slideUp(600);
				$('#post-speak').slideDown(400);
			});
			if($('.post-question-now').length>0){
				$('html,body').animate({
					scrollTop: $('.post-question-now').offset().top -100
				});
			}

			$('.close-answer-form').on('click',function(){
				$(this).parents('.answer-form').slideUp(400);
			});
			$('.answer-now').on('click',function(){
				$(this).siblings('.answer-form').slideDown(400);
			});
			$('#popup-user-login').on('change',function(){
				$('.login-error').hide();
			})
			if($('#report-form').length>0 && $('#report-form').hasClass('show-modal')){
				$('#modal-report').modal('show');
			}
		},
		afterRegistration:function(){
			$('#sendAgain').on('click',function(){
				var _this = $(this);
				var id = _this.data('id');
				var url = _this.data('url');
				var ret = _this.data('return');
				var alldata = {'id':id};
				if(ret && ret !=undefined){
					alldata.return = ret;
				}
				_this.attr('disabled','disabled');
				_this.html('Sending...');
				$.ajax({
					data:alldata,
					url:url,
					type:'post',
					dataType:'json',
					success:function(data){
						if(data.status=='success'){
							alert('Email Sent!');
						}else{
							alert('Could not send the email');
						}
					},
					complete:function(){
						_this.removeAttr('disabled');
						_this.html('Send Email Again');
					}
				});	
			})
		},
		submitSubscription:function(form) {
			var errorElmID = "subscribe-email-error";
			var _btn = $('#subscription_btn');
			_btn.html('<span>Requesting...</span>');
            //_btn.html('<img src="'+$(form).data('loader')+'"/>');
            _btn.attr('disabled','disabled');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                beforeSend:function(){
	          		 if($('#'+errorElmID).length>0){
          				$('#'+errorElmID).hide();
          			}
	          	},
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if (response.status == 'success') {
                            if(response.redirect_url){
                                window.location.href = response.redirect_url;
                            }
                            $(form)[0].reset();
                            $(form).find('.is-success').each(function(){
                                $(this).removeClass('is-success');
                            });
                           
                        } else if (response.status == 'error') {
                            if(response.message){
                                var element = $(form).find('input[name=email]');
                                element.removeClass('is-success').addClass('has-error');
                    			element.parent().addClass('has-error-wrap');
                                if($('#'+errorElmID).length>0){
			          				$('#'+errorElmID).html(response.message);
			          				$('#'+errorElmID).show();
			          			}else{
	                    			var div = document.createElement('span');
			          				div.className="has-error-txt error"; div.id=errorElmID;
			          				div.innerHTML = response.message;
			          				element.parent().append(div);
			          			}
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    _btn.html('<span>Subscribe Us</span>');
                    _btn.removeAttr('disabled');
                }
            }); //ajax close
        },
		submitForgotPassword:function(form) {
			var errorElmID = "fp-email-error";
			var _btn = $('#fp_btn');
			_btn.html('<span>Sending...</span>');
            //_btn.html('<img src="'+$(form).data('loader')+'"/>');
            _btn.attr('disabled','disabled');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                beforeSend:function(){
                	/*var element = '#'+errorElmID;
                	$(element).attr('data-original-title',"");
                    $(element).tooltip();*/
                    if($('#'+errorElmID).length>0){
          				$('#'+errorElmID).hide();
          			}
	          	},
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if(response.redirect_url !=undefined){
                            window.location.href = response.redirect_url;
                            return;
                        }
                        if (response.status == 'success') {
                            $(form)[0].reset();
                            $(form).find('.is-success').each(function(){
                                $(this).removeClass('is-success');
                            });
                            $(form).find('.form-group').append("<div class='alert alert-success alert-dismissible fp-success'>"+
                            									"<button aria-label='Close' data-dismiss='alert' class='close' type='button'><span aria-hidden='true'>×</span></button>"+
                            									"<p>An email was sent successfully to your account.<br> Please click on the link in the email and change your password.</p>"+
                            									"</div>");
                           
                        } else if (response.status == 'error') {
                            if(response.message){
                                /*var _element = $(form).find('input[name=email]');
                    			_element.parent().addClass('has-error-wrap');
                                _element.attr('data-original-title',response.message);
                    			_element.tooltip();*/
                                var element = $(form).find('input[name=email]');
                                element.removeClass('is-success').addClass('has-error');
                    			element.parent().addClass('has-error-wrap');
                                if($('#'+errorElmID).length>0){
			          				$('#'+errorElmID).html(response.message);
			          				$('#'+errorElmID).show();
			          			}else{
	                    			var div = document.createElement('span');
			          				div.className="has-error-txt error"; div.id=errorElmID;
			          				div.innerHTML = response.message;
			          				element.attr("aria-describedby",errorElmID);
			          				element.parent().append(div);
			          			}
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    _btn.html('<span>Send Email</span>');
                    _btn.removeAttr('disabled');
                }
            }); //ajax close
        },
        submitEnquiry: function(form){
        	var _btn = $(form).find('button[type=submit]');
        	_btn.html('<span>Sending...</span>');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                $(form)[0].reset();
		                var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
		                $('#modal-enquiry .modal-body').html(content);
		                $('#modal-enquiry .modal-footer .btn-success').hide();
		            }
		        },
		        complete:function(){
		        	_btn.html('<span>Send</span>');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        sendVideoRequest: function(form){
        	var _btn = $(form).find('button[type=submit]');
        	_btn.html('<span>Sending...</span>');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                $(form)[0].reset();
		                var content = '<div class="modal-body">'+
			                				'<div class="alert alert-' + type + ' alert-effect in">' + 
			                					'<i class="fa fa-' + icon + '"></i> ' + response.message + 
			                				'</div>'+
			                			'</div>'+
		                				'<div class="modal-footer">'+
											'<button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times"></i> Close</button>'+
										'</div>';
		                $(form).html(content);
		                _btn.hide();
		            }else{
		            	alert(response.message);
		            }
		        },
		        complete:function(){
		        	_btn.html('<span>Send</span>');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        sendPhoneOrder: function(form){
        	var _btn = $(form).find('button[type=submit]');
        	_btn.html('<span>Sending...</span>');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                /*if(response.redirect_url){
                            window.location.href = response.redirect_url;
                        }*/
		                $(form)[0].reset();
		                var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
		                $(form).html(content);
		                /*$('#modal-enquiry .modal-body').html(content);
		                $('#modal-enquiry .modal-footer .btn-success').hide();*/
		            }else{
		            	alert(response.message);
		            }
		        },
		        complete:function(){
		        	_btn.html('<span>Send</span>');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        sendQuestion: function(form){
        	var _btn = $(form).find('button[type=submit]');
        	_btn.html('Sending...');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                if(response.redirect_url){
                            window.location.href = response.redirect_url;
                        }else{
			                $(form)[0].reset();
			                var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
			                $(form).html(content);
                        }
		            }else{
		            	alert(response.message);
		            }
		        },
		        complete:function(){
		        	_btn.html('Post Question');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        postAnswer:function(form){
			var _btn = $(form).find('button[type=submit]');
        	_btn.html('Posting...');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                if(response.answer){
		                	var _ansdiv = $(form).parents('.buyer-comment').find('.answer');
		                	_ansdiv.append(response.answer);
		                	_ansdiv.slideDown(500);
		                	$(form).parents('.answer-now-section').hide(500);
		                }
		                alert('Your answer was posted successfully!')
		            }else{
		            	alert(response.message);
		            }
		        },
		        complete:function(){
		        	_btn.html('Post');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        sendReport:function(form){
			var _btn = $(form).find('button[type=submit]');
        	_btn.html('<span>Sending...</span>');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            var icon = 'warning';
		            var type = 'danger';
		            if (response.status == 'success') {
		                icon = 'check';
		                type = 'success';
		                $(form)[0].reset();
		                var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
		                $('#modal-report .modal-body').html(content);
		                $('#modal-report .modal-footer .btn-success').hide();
		            }
		        },
		        complete:function(){
		        	_btn.html('<span>Send</span>');
        			_btn.removeAttr('disabled');
		        }
		    });
        },
        addToCart: function(form){
        	var loader = $("#loaders").data('bars');
			var _btn = $(form).find('button[type=submit]');
			_btn.blur();
        	_btn.html('<span><img src="'+loader+'" /></span>');
        	_btn.attr('disabled','disbaled');
		    $.ajax({
		        url: $(form).attr('action'),
		        data: $(form).serialize(),
		        type: 'POST',
		        success: function(response) {
		            if (response.status == 'success') {
		                content = response.html;
		                $('.shopping-cart-count').html(response.cart_count);
		                $('.shopping-cart-total').html(response.cart_total);
		                $('#modal-cart-request .modal-body').html(content);
		                $('#modal-cart-request').modal('show');
		            }
		        },
		        complete:function(){
		        	_btn.html('<span>Buy Now </span> <i class="zmdi zmdi-shopping-cart"></i>');
        			_btn.removeAttr('disabled');
		        }
		    });
		},
		saveReview:function(form) {
        	var _btn = $(form).find('button.go');
            _btn.html('Submitting...');
            _btn.attr('disabled','disabled');
            $.ajax({
                data: $(form).serialize(),
                url: $(form).attr('action'),
                type: 'post',
                dataType:'JSON',
                success: function(response) {
                    if (response) {
                        //var response = $.parseJSON(data);
                        if (response.status == 'success') {
                        	if(response.isAdmin==true){
                        		var allData = response.html;
                        		var content = $(allData).find('ul').html();
                        		$('#reviews-list ul').prepend(content);
                        		$('#review-form')[0].reset();
                        		$('#review-user-image img').attr('src',$('#review-user-image img').data('src'));
                        		$('html,body').animate({
	                           		scrollTop: $('#reviews-list').offset().top-100
	                           	});
                        	}else{
	                            $('.review-form').html(response.html);
	                           	$('html,body').animate({
	                           		scrollTop: $('.review-form').offset().top-100
	                           	});
                        	}
                        } else if (response.status == 'error') {
                            if(response.error_msg){
                              
                            }else{
                                alert(response.message);
                            }
                        }
                    } //data close

                }, //success close
                complete: function(){
                    _btn.html('Submit <i class="fa fa-long-arrow-right"></i>');
                    _btn.removeAttr('disabled');
                }
            }); //ajax close
        },
		defineValidateUserName:function(formELm,id){
			var checkUsernameUrl = $(formELm).data('checkusername');
			$(formELm+' :input[name="username"]').change(function() {
			  	$(this).removeData("previousValue");
			});

		    var result = false;
			$.validator.addMethod("validateUserName", function(value, element){
			    var inputElem = $(formELm+' :input[name="username"]'),
			        data = { "username" : inputElem.val() },
			        eReport = ''; //error report
			    if(id != undefined){
			    	data.user_id = id;
			    }
			    $.ajax({
			        url: checkUsernameUrl,
		          	type: "post",
		          	dataType:'json',
		          	async:false,
		          	data: data,
		          	beforeSend:function(){
		          		var fb = $("#loaders").data('fadingblock');
		          		var loader = $(formELm).find('#username-block .username-check-loader');
		          		if($('#username-error').length>0){
	          				$('#username-error').hide();
	          			}
		          		if(loader.length>0){
		          			loader.show();
		          		}else{
		          			$(formELm).find('#username-block').append("<img src='"+fb+"' class='username-check-loader'/>");
		          		}
		          	},
		          	success:function(data){
		          		var elm = $('#username-block');
		          		var loader = elm.find('.username-check-loader');
		          		var msg = "The username is already taken!";
		          		loader.hide();
		          		if(data.status=="true"){
		          			$('#username-error').hide();
	          				result= true;
		          		}else if(data.status=='false'){
		          			if($('#username-error').length>0){
		          				$('#username-error').html(msg);
		          			}else{
		          				var div = document.createElement('span');
		          				div.className="has-error-txt error"; div.setAttribute("for","username"); div.id="username-error";
		          				div.innerHTML = msg;
		          				elm.append(div);
		          			}
		          			result= false;
		          		}else{
		          			alert('Something went wrong!');
		          		}
		          	},
		          	error: function(xhr, textStatus, errorThrown)
			        {
			            alert('ajax loading error... ... '+url + query);
			            result= false;
			        }
			    });
				return result;

			}, '');
		},
		defineValidateUserEmail:function(formELm,id){
			var checkUrl = $(formELm).data('checkemail');
			$(formELm+' :input[name="email"]').change(function() {
			  	$(this).removeData("previousValue");
			});

			$.validator.addMethod("validateUserEmail", function(value, element){
			    var inputElem = $(formELm+' :input[name="email"]'),
			        data = { "email" : inputElem.val() },
			        eReport = ''; //error report
			    if(id != undefined){
			    	data.user_id = id;
			    }

			    var result = false;
			    $.ajax({
			        url: checkUrl,
		          	type: "post",
		          	dataType:'json',
		          	async:false,
		          	data: data,
		          	beforeSend:function(){
		          		var fb = $("#loaders").data('fadingblock');
		          		var loader = $(formELm).find('#email-block .email-check-loader');
		          		if($('#email-error').length>0){
	          				$('#email-error').hide();
	          			}
		          		if(loader.length>0){
		          			loader.show();
		          		}else{
		          			$(formELm).find('#email-block').append("<img src='"+fb+"' class='email-check-loader'/>");
		          		}
		          	},
		          	success:function(data){
		          		var elm = $('#email-block');
		          		var loader = elm.find('.email-check-loader');
		          		var msg = "The email address is already taken!";
		          		loader.hide();
		          		if(data.status=="true"){
		          			$('#email-error').hide();
	          				result= true;
		          		}else if(data.status=='false'){
		          			if($('#email-error').length>0){
		          				$('#email-error').html(msg);
		          			}else{
		          				var div = document.createElement('span');
		          				div.className="has-error-txt error"; div.setAttribute("for","email"); div.id="email-error";
		          				div.innerHTML = msg;
		          				elm.append(div);
		          			}
		          			result= false;
		          		}else{
		          			alert('Something went wrong!');
		          		}
		          	},
		          	error: function(xhr, textStatus, errorThrown)
			        {
			            alert('ajax loading error... ... '+url + query);
			            result= false;
			        }
			    });
				return result;

			}, '');
		},
		validateForms:function(){
			$('#add-cart-form').validate({
				rules:{
					variant:{required:true},
					quantity:{required:true}
				},
				messages:{
					variant:{required:"Please select a variant!"},
					quantity:{required:"Please select a quantity!"},
				},
                errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.addToCart(form);    
                }
			})
			//new add to cart form
			$('#add-to-cart-form').validate({
				rules:{
					quantity:{required:true}
				},
				messages:{
					quantity:{required:"Please select a quantity!"},
				},
                errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.addToCart(form);    
                }
			})
			
			$('.wishlist-cart-form').each(function(){
				var _form = $(this);
				_form.validate({
					rules:{
						quantity:{required:true}
					},
					messages:{
						quantity:{required:"Please select a quantity!"},
					},
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                },
	                submitHandler: function(form) {
	                    fn.addToCart(form);    
	                }
				});
			});

			$('#contact-form').validate({
                ignore: [],
                rules: {
                    first_name: { required: true },
                    last_name: { required: true },
                    email: { required: true, email: true },
                    phone: { minlength: 7 },
                    subject: { required: true },
                    message: { required: true },
                    'g-recaptcha-response': { required: true }
                },
                messages: {
                    first_name: { required: 'Please enter your first name.' },
                    last_name: { required: 'Please enter your last name.' },
                    email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
                    phone: { minlength: 'Phone number must be minimum of 7 digits.' },
                    /*country: {required: 'Please enter your country.'},*/
                    subject: { required: 'Please enter the subject of the email' },
                    message: { required: 'Please enter the message of the email' },
                    'g-recaptcha-response': { required: 'Captcha is required' }
                },
                errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    //$(form).find('button[type=submit]').attr('disabled','disabled');
                    form.submit();
                }
            });
			$('#user-login').validate({
				rules:{
					email:{required:true, email:true},
					password:{required:true}
				},
				messages:{
					email:{required:"Please enter your email address!"},
					password:{required:"Please enter your password!"},
				},
				errorPlacement: function (error, element) {
				    $(element).parents('.form-group').append(error);
				},
				errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parents('.form-group').addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parents('.form-group').removeClass('has-error-wrap');
                },
			});
			$('#menu-user-login').validate({
				rules:{
					email:{required:true, email:true},
					password:{required:true}
				},
				messages:{
					email:{required:"Please enter your email address!"},
					password:{required:"Please enter your password!"},
				},
				errorPlacement: function (error, element) {
				    $(element).parents('.form-group').append(error);
				},
				errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parents('.form-group').addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parents('.form-group').removeClass('has-error-wrap');
                },
			});

			if($('#user-register').length>0){
				fn.defineValidateUserEmail('#user-register');
				fn.defineValidateUserName('#user-register');
				$('#user-register').validate({
					onkeyup:false,
					rules:{
						first_name:{required:true},
						last_name:{required:true},
						email:{required:true, email:true,validateUserEmail:true},
						username:{validateUserName:true},
						password:{required:true},
						confirm_password:{required:true,equalTo:'#register_password'}
					},
					messages:{
						first_name:{required:"Please enter your first name!"},
						last_name:{required:"Please enter your last name!"},
						email:{required:"Please enter your email address!",validateUserEmail:"The email address is already taken!"},
						username:{validateUserName:"This username is already used!"},
						password:{required:"Please enter your password!"},
						confirm_password:{required:"Please enter the password again!",equalTo:"This value should match with the password!"}
					},
					errorClass:'has-error-txt error',
					errorElement:'span',
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                    $(element).parent().addClass('has-error-wrap');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                    $(element).parent().removeClass('has-error-wrap');
	                },
				});
			}

			if($('#edit_profile').length>0){
				fn.defineValidateUserName('#edit_profile',$('#profile_id').val());
				fn.defineValidateUserEmail('#edit_profile',$('#profile_id').val());
				$('#edit_profile').validate({
					onkeyup:false,
					rules:{
						first_name:{required:true},
						last_name:{required:true},
						username:{required:true,validateUserName:true},
						email:{required:true,email:true,validateUserEmail:true},
					},
					messages:{
						first_name:{required:"Please enter your first name!"},
						last_name:{required:"Please enter your last name!"},
						username:{required:"Please enter your username!",validateUserName:"The username is already taken!"},
						email:{required:"Please enter your email address!",validateUserEmail:"The email address is already taken!"},
					},
					errorClass:'has-error-txt error',
					errorElement:'span',
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                    $(element).parent().addClass('has-error-wrap');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                    $(element).parent().removeClass('has-error-wrap');
	                },
				});
			}

			if($('#edit_email').length>0){
				
				$('#edit_email').validate({
					onkeyup:false,
					rules:{
						email:{required:true,email:true,validateUserEmail:true},
					},
					messages:{
						email:{required:"Please enter your email address!",validateUserEmail:"The email address is already taken!"},
					},
					errorClass:'has-error-txt error',
					errorElement:'span',
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                    $(element).parent().addClass('has-error-wrap');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                    $(element).parent().removeClass('has-error-wrap');
	                },
				});
			}
			if($('#edit_password').length>0){
				$('#edit_password').validate({
					onkeyup:false,
					rules:{
						password:{required:true},
						confirm_password:{required:true,equalTo:password}
					},
					messages:{
						password:{required:"Please enter your password!"},
						confirm_password:{required:"Please enter the password again!",equalTo:"This value should match with the password!"}
					},
					errorClass:'has-error-txt error',
					errorElement:'span',
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                    $(element).parent().addClass('has-error-wrap');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                    $(element).parent().removeClass('has-error-wrap');
	                },
				});
			}


			$('#checkout-form').validate({
				rules:{
					"billing[first_name]":{required:true},
					'billing[last_name]':{required:true},
					"billing[email]":{required:true,email:true},
					"billing[phone]":{required:true},
					"billing[address]":{required:true},
					"billing[country_id]":{required:true},
					"billing[city]":{required:true},
					//"billing[state_id]":{required:true},
					/*"billing[zipcode]":{required:true},*/
					"shipping[first_name]":{required:true},
					'shipping[last_name]':{required:true},
					//"shipping[phone]":{required:true},
					"shipping[address]":{required:true},
					"shipping[country_id]":{required:true},
					"shipping[city]":{required:true},
					//"shipping[state_id]":{required:true},
					/*"shipping[zipcode]":{required:true},*/
					payment_type:{required:true}
				},
				messages:{
					"billing[first_name]":{required:"Please enter your first name."},
					'billing[last_name]':{required:"Please enter your last name."},
					"billing[email]":{required:"Please enter your email address.",email:"Please enter valid email address."},
					"billing[phone]":{required:"Please enter your phone number."},
					"billing[address]":{required:"Please enter your address."},
					"billing[country_id]":{required:"Please select your country."},
					"billing[city]":{required:"Please enter your city."},
					//"billing[state_id]":{required:"Please select your state."},
					/*"billing[zipcode]":{required:"Please enter your zip code."},*/
					"shipping[first_name]":{required:"Please enter first name for shipping."},
					'shipping[last_name]':{required:"Please enter last name for shipping."},
					//"shipping[phone]":{required:"Please enter your first name for shipping."},
					"shipping[address]":{required:"Please enter address for shipping."},
					"shipping[country_id]":{required:"Please select country for shipping."},
					"shipping[city]":{required:"Please enter city for shipping."},
					//"shipping[state_id]":{required:"Please select state for shipping."},
					/*"shipping[zipcode]":{required:"Please enter zipcode for shipping."},*/
					payment_type:{required:"Please enter payment method."}
				},
				errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                focusInvalid: false,
			    invalidHandler: function(form, validator) {

			        if (!validator.numberOfInvalids())
			            return;

			        $('html, body').animate({
			            scrollTop: $(validator.errorList[0].element).offset().top - 100
			        }, 1000);
			        (validator.errorList[0].element).focus();
			    }
			});

			$('#subscribe-us').validate({
                rules: {
                    email: { required: true,email:true},
                },
                messages: {
                    email: { required: "Please enter your email address.", },
                },
                errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.submitSubscription(form);    
                }
            });

			$('#search-form').validate({
				rules: { keyword: { required: true}, },
                messages: { keyword: { required: "Please enter a keyword to search.", }, },
				errorPlacement: function (error, element) {
                    $(element).attr('data-original-title',$(error).text());
                    $(element).tooltip();
                },
                success: function (label, element) {
                    $(element).attr('data-original-title','');
                    $(element).tooltip();
                },
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
			});
			$('#forgot-password').validate({
				rules: {
                    email: { required: true,email:true},
                },
                messages: {
                    email: { required: "Please enter your email address.", },
                },
                errorClass:'has-error-txt error',
				errorElement:'span',
                /*errorPlacement: function (error, element) {
                    $(element).attr('data-original-title',$(error).text());
                    $(element).tooltip();
                },
                success: function (label, element) {
                    $(element).attr('data-original-title','');
                    $(element).tooltip();
                },*/
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.submitForgotPassword(form);    
                }
			});

			$('#enquiry-form').validate({
			    ignore: [],
			    rules: {
			        name: { required: true },
			        email: { required: true, email: true },
			        phone: { minlength: 7 },
			        subject: { required: true },
			        message: { required: true },
			        'g-recaptcha-response':{required:true}
			    },
			    messages: {
			        name: { required: 'Please enter your full name.' },
			        email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
			        phone: { minlength: 'Phone number must be minimum of 7 digits.' },
			        subject: { required: 'Please enter the subject of the email' },
			        message: { required: 'Please enter the message of the email' },
			        'g-recaptcha-response': { required: 'Captcha is required' }
			    },
			    errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.submitEnquiry(form);    
                }
			});

			$('#phone-order-form').validate({
			    // ignore: [],
			    rules: {
			        name: { required: true },
			        phone: { required:true, minlength: 7 },
			    },
			    messages: {
			        name: { required: 'Please enter your full name.' },
			        phone: { required:'Please enter your mobile/phone no.' , minlength: 'Phone number must be minimum of 7 digits.' },
			    },
			    errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.sendPhoneOrder(form);    
                }
			});
			$('#video-request-form').validate({
			    // ignore: [],
			    rules: {
			        name: { required: true },
			        email: { required:true, email: true },
			    },
			    messages: {
			        name: { required: 'Please enter your full name.' },
			        email: { required:'Please enter your email.'  },
			    },
			    errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.sendVideoRequest(form);    
                }
			});
			$('#report-form').validate({
 				rules: {
			        reason: { required: true },
			    },
			    messages: {
			        reason: { required: 'Please enter your reason.' },
			    },
			    errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.sendReport(form);    
                }
			});
			$('#question-form').validate({
 				rules: {
			        question: { required: true },
			    },
			    messages: {
			        question: { required: 'Please enter your question.' },
			    },
			    errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parent().addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parent().removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                    fn.sendQuestion(form);    
                }
			});
			$('.answer-form').each(function(){
				var _form = $(this);
				_form.validate({
	 				rules: {
				        answer: { required: true },
				    },
				    messages: {
				        answer: { required: 'Please enter your answer.' },
				    },
				    errorClass:'has-error-txt error',
					errorElement:'span',
					highlight: function(element) {
	                    $(element).removeClass('is-success').addClass('has-error');
	                    $(element).parent().addClass('has-error-wrap');
	                },
	                unhighlight: function(element) {
	                    $(element).removeClass('has-error').addClass('is-success');
	                    $(element).parent().removeClass('has-error-wrap');
	                },
	                submitHandler: function(form) {
	                    fn.postAnswer(form);    
	                }
				});
			})

			$('#popup-user-login').validate({
				rules:{
					email:{required:true, email:true},
					password:{required:true}
				},
				messages:{
					email:{required:"Please enter your email address!"},
					password:{required:"Please enter your password!"},
				},
				errorPlacement: function (error, element) {
				    $(element).parents('.form-group').append(error);
				},
				errorClass:'has-error-txt error',
				errorElement:'span',
				highlight: function(element) {
                    $(element).removeClass('is-success').addClass('has-error');
                    $(element).parents('.form-group').addClass('has-error-wrap');
                },
                unhighlight: function(element) {
                    $(element).removeClass('has-error').addClass('is-success');
                    $(element).parents('.form-group').removeClass('has-error-wrap');
                },
                submitHandler: function(form) {
                	var btn = $(form).find('button[type=submit]');
                	btn.html('Validating...');
                	btn.attr('disabled','disabled');
                	var result = false;
                    $.ajax({
                    	url:$(form).data('url'),
                    	data:$(form).serialize(),
                    	type:'post',
                    	dataType:'json',
                    	async:false,
                    	success:function(response){
                    		if(response.status=='success'){
                    			result=true;
                    		}else if(response.status=='error'){
                    			if($('.login-error').length>0){
                    				$('.login-error').show();
                    			}else{
	                    			var content = '<div class="alert alert-danger alert-effect in login-error">' + '<i class="zmdi zmdi-alert-triangle"></i> Either username or password is incorrect!</div>';
	                    			$(form).find('h5').after(content);
                    			}
                    		}else{
                    			alert('Something went wrong!');
                    		}
                    	},complete:function(){
                    		btn.html('Login');
                			btn.removeAttr('disabled');
                    	}
                    });
					if(result){
						form.submit();
					}
                }
			});
			//REVIEW FORM
            $('#review-form').validate({
                rules: { 'author': { required: true },
                    'email': { required: true, email: true },
                    'title': { required: true, },
                    'content': { required: true, minlength: 10, },
                },
                messages: { 
                    'author': { required: 'Please enter your Full Name.' },
                    'email': { required: 'Please enter your Email Address.' },
                    'title': { required: 'Please enter Review Title.' },
                    'content': { required: 'Please enter the Review.' },
                },
                highlight: function(element) {
                    $(element).addClass('msg-danger');
                },
                unhighlight: function(element) {
                    $(element).removeClass('msg-danger');
                },
                submitHandler: function(form) {
                    fn.saveReview(form);
                }
            });
		},//end validate

		slick:function(){
			 $('.slider-for').slick({
			   slidesToShow: 1,
			   slidesToScroll: 1,
			   focusOnSelect:true,
			   arrows: false,
			   fade: true,
			   dots: true,
			   pauseOnHover:true,
			   pauseOnDotsHover:true,
			   pauseOnFocus:true,
			   asNavFor: '.slider-nav',
			   infinite:true
			});
			$('.slider-nav').slick({
			 
				slidesToScroll: 1,
				asNavFor: '.slider-for',
				dots: false,
				centerMode: true,
				focusOnSelect: true,
				variableWidth:false,
				waitForAnimate:false,
				autoplay: true,
				autoplaySpeed: 4000,
				centerPadding: '40px',
				slidesToShow: 3,
				pauseOnHover:true,
				pauseOnDotsHover:true,
				pauseOnFocus:true,
				arrows: false,
				infinite:true
			  // responsive: [
			  //   {
			  //     breakpoint: 768,
			  //     settings: {
			  //       arrows: false,
			  //       centerMode: true,
			  //       centerPadding: '40px',
			  //       slidesToShow: 3
			  //     }
			  //   },
			  //   {
			  //     breakpoint: 480,
			  //     settings: {
			  //       arrows: false,
			  //       centerMode: true,
			  //       centerPadding: '40px',
			  //       slidesToShow: 1
			  //     }
			  //   }
			  // ]
			});
		},
		tab_to_select:function(){
			$(document).ready(function() {

			  $(window).resize(responsive);
			  $(window).trigger('resize');

			});

			function responsive(){

			  // get resolution
			  var resolution = document.documentElement.clientWidth;

			  // because mobile layout
			  if (resolution < 768) {

			    if( $('.select_wrapper').length === 0 ) {


			        var select_wrapper = "<div class='select_wrapper'>";
				    var select = "<select class='nav-tab-select form-control'>";
				    $(".arrival-tab .nav-tabs li").each(function (ind) {
				        var _this = $(this);
				        var text = _this.text();
				        option = "<option value='" + ind + "'>" + text + "</option>";
				        select += option;
				    });
				    select += "</select>";
				    select_wrapper += select + "</div><!-- /.select_wrapper-->";
				    $(select_wrapper).insertBefore(".arrival-tab .nav-tabs");
				    $(".arrival-tab .nav-tabs").hide();


				    $('.nav-tab-select').on('change', function (e) {

					    $('.arrival-tab .nav-tabs li a').eq($(this).val()).tab('show');

					});

			    }

			  }

			  // max width 979px
			  if (resolution > 979) {
			    $('.select_wrapper').remove();
			    $('.arrival-tab .nav-tabs').show();
			  }

			}
		},
		color_select:function(){
			$(".color-selection-block label[data-color]").each(function(){
				$(this).css({
					'background-color':$(this).data('color')
				});
			});
		},
		progressiveImage:function(){
			// progressive images
            function resizeElements(){
             var winWidth=$(window).width();
               if(winWidth > 992){
                 $(".figure-image").each(function(){
                    $(this).attr("style",$(this).attr("srcdesktop"));
                 }); 
               }
               if(winWidth < 992){
                  $(".figure-image").each(function(){
                      $(this).attr("style",$(this).attr("srctablet"));
                   }); 
               }
               if(winWidth < 768){
                  $(".figure-image").each(function(){
                      $(this).attr("style",$(this).attr("srcphablet"));
                   }); 
               }
               if(winWidth < 480){
                 $(".figure-image").each(function(){
                    $(this).attr("style",$(this).attr("srcmobile"));
                 }); 
               }
             }



             $(window).bind('resize', function () {
                resizeElements();
            }).trigger('resize');
		}

		
	};//var fn
});


$(document).ready(function(){
	fn.launch();

	if($('#prd-video').length>0){
		fn.controlYoutubeVideo();
	}
	var formId = "#add-to-cart-form";
	if($(formId).length>0){
		fn.variantSelection(formId);
	}

	if($('#load-more-reviews').length >0 ){
    	fn.loadReviews();
    }
    if($('#load-more-testimonials').length >0 ){
    	fn.loadTestimonials();
    }
});

function onPlayerReady(event) {
  	// bind events
  	$('#modal-video').on('hide.bs.modal',function(){
  		player.pauseVideo();
  	});
  	/*var playButton = document.getElementById("play-button");
  	playButton.addEventListener("click", function() {
	    player.playVideo();
  	});
	  
  	var pauseButton = document.getElementById("pause-button");
  	pauseButton.addEventListener("click", function() {
	    player.pauseVideo();
  	});*/
	  
}
function onYouTubePlayerAPIReady() {
	// create the global player from the specific iframe (#video)
  	player = new YT.Player('prd-video', {
	    events: {
	      // call this function when player is ready to use
	      'onReady': onPlayerReady
	    }
  	});
}