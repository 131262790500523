/*var defaultOptions = {
	filterElm : '#ajax-filter',
	prdData :{},
	condition: {},
};

function filterProducts(options){
	this.build(options);
}

filterProduct.prototype = {
	constructor: filterProducts,

	build: function(options){
		var self = this;
       	self.options = $.extend({}, defaultOptions, options);
	},
	initialize: function(){
		var _options = this.options;

	}
}*/
var filters,selections,condition,url,newurl;
$(document).ready(function(){
	if($('#ajax-filter').length>0){
		var datafilter = $('#ajax-filter').attr('data-filters');
		filters = JSON.parse(datafilter);
		newurl = url = $('#ajax-filter').data('url');
		
		var sort = filters.sort;
		if(sort && sort != undefined){
			var _selSort = $(".sort-list a.sort-data[data-val='"+sort+"']");
			if(_selSort.length>0){
				$('.sort-list a.dropdown-toggle').html(_selSort.html()+" <i class='zmdi zmdi-chevron-down'></i>");
			}
		}
		//loadAjax();

		$('#show-product-filters input[type=checkbox]').on('change',function(){
			if($("#show-product-filters input[name='category[]']:checked").not('#parent-category').length==0){
				$("#parent-category").prop('checked',true);
			}else{
				$("#parent-category").prop('checked',false);
			}
			if($("#show-product-filters input[name='filters[]']:checked").not('#filter-option-id').length==0){
				$("#filter-option-id").prop('checked',true);
			}else{
				$("#filter-option-id").prop('checked',false);
			}
			filters.selections = $('#show-product-filters input[type=checkbox]:checked').serialize();
			newurl=url;
			loadAjax();
		});

		$('.sort-list a.sort-data').click(function(){
			filters.sort = $(this).data('val');
			var name = $(this).html();
			$(this).parents('.show-dropdown').removeClass('open');
			loadAjax();
			$('.sort-list a.dropdown-toggle').html(name+" <i class='zmdi zmdi-chevron-down'></i>");
		});

		$(document).on('click','.show-all-prds',function(e){
			e.preventDefault();
			$(this).html('Loading...');
			filters.show_all = true;
			loadAjax();
		});

		$(document).on('click','.paginate-prds',function(e){
			e.preventDefault();
			$(this).html('Loading...');
			filters.show_all = undefined;
			loadAjax();
		})

		$(document).on('click','.filter-toolbar__paginate a',function(e){
			e.preventDefault();
			var href = $(this).attr('href');
			if(href=="#"){
				return false;
			}
			var page = urlParam(href,'page');
			if(newurl.indexOf('?') === -1){
			  	newurl = newurl+"?page="+page;
			}else{
				if(newurl.indexOf('page=') === -1){
					newurl = newurl+"&page="+page;
				}else{
					console.log('here');
					var oldPage = urlParam(newurl,'page');
					newurl = newurl.replace('page='+oldPage,'page='+page);
				}
			}
			filters.selections = $('#show-product-filters input[type=checkbox]:checked').serialize();
			loadAjax();
		});

		
	}
	if($('#list-products').length>0){
		MHonImageLoad();
	}

	function loadAjax(){
		var _block = $('#list-products');
		var loader = $("#loaders").data('loader');
		if($('body').find('.prd-loader').length>0){
			$('body').find('.prd-loader').show();
		}else{
			$('body').prepend("<img src='"+loader+"' class='prd-loader'/>");
		}
		$('body').addClass('prd-loading');
		$.ajax({
			data:filters,
			type:'POST',
			url:newurl,
			/*async:false,*/
			success:function(data){
				var html = data.html;
				var sel_brands = data.brands;
				var sel_categories = data.categories;
				var sel_filters = data.filters;
				$('#list-products').html($(html).find('#list-products').html());
				$('.filter-toolbar__detail').html($(html).find('.filter-toolbar__detail').html());
				$('.filter-toolbar__paginate').html($(html).find('.filter-toolbar__paginate').html());
				$('.show-filterpage').html($(html).find('.show-filterpage').html());
				$('.filter-showall').html($(html).find('.filter-showall').html());
				fn.applyMatchHeight();
				disableCheckboxByArr('brand',sel_brands);
				disableCheckboxByArr('category',sel_categories);
				disableFilters('filters',sel_filters);
				$("html,body").animate({
					scrollTop:$("#show-product-filters").offset().top
				},1000);
				MHonImageLoad();
			},
			complete:function(){
				$('body').find('.prd-loader').hide();
                $('body').removeClass('prd-loading');
			}
		});
	}

	function disableCheckboxByArr(name,array){
		$("#show-product-filters input[name='"+name+"[]']").not('#parent-category').each(function(){
			var val = parseInt($(this).val());
			if($.inArray(val,array)==-1){
				$(this).attr('disabled','disabled');
				$(this).parent().attr('style','text-decoration: line-through;color:#999');
			}else{
				$(this).removeAttr('disabled');
				$(this).parent().removeAttr('style');
			}
		})
	}

	function disableFilters(name,obj){
		$.each(obj,function(index,array){
			$("#show-product-filters input[name='"+name+"["+index+"][]']").each(function(){
				var val = parseInt($(this).val());
				if($.inArray(val,array)==-1){
					$(this).attr('disabled','disabled');
					$(this).parent().attr('style','text-decoration: line-through;color:#999');
				}else{
					$(this).removeAttr('disabled');
					$(this).parent().removeAttr('style');
				}
			});
		});
	}

	function urlParam(url,name){
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		if (results==null){
			return null;
		}else{
			return results[1] || 0;
		}
	}

	function MHonImageLoad(){
		var c = 0, start = new Date().getTime();
		$('#list-products').imagesLoaded()
		.progress(function(instance, image) {
			c++;
			if(c%10 == 0){
				var end = new Date().getTime();
				var time = end - start;//in milliseconds
				console.log('images loading process in >>'+time);
				if(time > 100){
					fn.applyMatchHeight();
					console.log('match height applied');
					start = new Date().getTime()
				}
			}
			//var result = image.isLoaded ? 'loaded' : 'broken';
			//console.log( 'image is ' + result + ' for ' + image.img.src );
		})
		.always(function(){
			fn.applyMatchHeight();
			console.log('all images loaded');
		});
	}

});